<template>
  <div class="width-100 height-100 ">
    <div class="video-mask" @click="hide"></div>
    <div class="video-content">
      <div class="row-end ">
        <i class="el-icon-close pointer" @click="hide"></i>
      </div>
      <div class="video-play flex-center">
          <iframe :src="src+'?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0'" frameborder="0" width="100%" height="500px" v-if="videoType=='other'"></iframe>
        <video  width="880" height="480" controls autoplay v-else>
          <source :src="src" type="video/mp4"></video>
         
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props:{
    src:{
      type:String,
      default:""
    },
    videoType:{
      type:String,
      default:'other'
    }
  },

  data() {
    return {};
  },

  created() {},

  mounted() {},

  methods: {
    hide(){
      this.$emit('hide')
    }
  },
};
</script>
<style lang="less" scoped>
.video-mask {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
.video-content{
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  .video-play{
    width: 900px;
    height: 500px;
    background-color: #000;
    margin-top: 10px;
  }
  i{
    font-size: 20px;
    color: #fff;
  }
}
</style>
