<template>
  <div class="home">
    <heard-view></heard-view>
    <!-- 主体区域开始 -->
    <!-- 顶部 -->
    <div class="home-main posi-rel width-100">
		<div class="home-heard-main width-100 flex-center">
			<div class="home-left column">
				<div class="data_view_left">
					<div class="font-weight-bolder">Best Dropshipping Agent.</div>
					<div class="view_text">
					  Our mission is to make it easy to do dropshipping business anywhere
					</div>
				</div>
				<div class="view_vheck_text">
					<div class="row view_left">
						<i class="el-icon-success view_left_check"></i>
						<div class="view_left_title font-weight-bolder font-small">NO MOQ</div>
					</div>
					<div class="view_text">
					  STfulfill is a free dropshipping agent. You don’t need to have a lot of orders before working with us.
					</div>
				</div>
			</div>
			<div class="home-main_video  posi-rel">
			  <img
			    src="../../assets/login-5.png"
			    style="width: 515px;height: 93px;"
			    alt=""
			  />
			  <img
			    src="../../assets/home-video.png"
			    class="main_video_img pointer posi-abs"
			    alt=""
			    @click="videoShow = true"
			  />
			</div>
			<div class="main_data_view row">
				<img src="../../assets/home-4.png" alt="">
				<div class="height-100 column data_view_right">
					<div>
						<div class="view_right_hours row font-weight-bolder">
						  <i class="el-icon-arrow-left main-color font-weight-bolder"></i>
						  <div class="main-color hours_text font-weight-bolder">48</div>
						  <div class="min-title">hours</div>
						</div>
						<div class="view_hours_title">Orders Get Processed</div>
					</div>
					<div>
						<div class="view_right_hours row font-weight-bolder">
						  <div class="main-color hours_text font-weight-bolder">6-12</div>
						  <div  class="min-title">days</div>
						</div>
						<div class="view_hours_title">Fast And Trackable Shipping</div>
					</div>
					<div>
						<div class="view_right_hours row font-weight-bolder">
						  <div class="main-color hours_text font-weight-bolder">99.8%</div>
						</div>
						<div class="view_hours_title">Packages Received Perfectly</div>
					</div>
				</div>
			</div>
		</div>
		<div class="width-100 flex-center main-button">
		  <div class="pointer bg-color" @click="navTo('/login')">JOIN FREE</div>
		</div>
		<div class="width-100 flex-center">
			<div class="direction">
				<div class="strengths-ttle">Our strengths</div>
				<div class="strengths-ttle_min">To give you the ultimate experience</div>
			</div>
		</div>
    </div>
    <!-- 优势区域 -->
    <div class="width-100 home-strengths direction">

      <div class="strengths-item row-start" id="boxFixed">
        <div class="strengths-item-text">
          <div
            class="item-text-title"
            :class="isFixed ? 'animated fadeInUp' : ''"
          >
            We Will Assign A
            <span class="main-color">Client Account Manager</span>
          </div>
          <div class="item-text" :class="isFixed ? 'animated fadeInUp' : ''">
            Get 1-1 support from our dedicated manager who will handle
            everything with the supplier for your dropshipping business.
          </div>
          <div class="item-button  bg-color">
            Professional & Passionate
          </div>
        </div>
        <img src="../../assets/home-6.png" alt="" />
      </div>
      <div class="strengths-item-one row-start" id="boxFixed1">
        <img src="../../assets/home-7.png" alt="" />
        <div class="strengths-item-text">
          <div
            class="item-text-title"
            :class="isFixed1 ? 'animated fadeInUp' : ''"
          >
            We Will Cover The <span>After Sales</span>
          </div>
          <div class="item-text" :class="isFixed1 ? 'animated fadeInUp' : ''">
            We inspect the product quality for you. And if parcels are lost in
            transit or get damaged by local carriers, we will cover the loss or
            reship the products to your customers the very next day.
          </div>
          <div class="item-button row-end">
            <div class="">Learn More</div>
          </div>
        </div>
      </div>
      <div class="strengths-item row-start strengths-item-copy" id="boxFixed2">
        <div class="strengths-item-text">
          <div
            class="item-text-title"
            :class="isFixed2 ? 'animated fadeInUp' : ''"
          >
            We Will Automatically <span>Sync The Data</span>
          </div>
          <div class="item-text" :class="isFixed2 ? 'animated fadeInUp' : ''">
            Connect your store with STfulfilL, so you needn’t to fill in orders
            manually. Save time and energy to scale you business.
          </div>
          <div class="item-button  bg-color">Advanced & Considerate</div>
        </div>
        <img src="../../assets/home-8.png" alt="" />
      </div>
      <div
        class="strengths-item-one strengths-item-one-copy row-start"
        id="boxFixed3"
      >
        <img src="../../assets/home-9.png" alt="" />
        <div class="strengths-item-text">
          <div
            class="item-text-title"
            :class="isFixed3 ? 'animated fadeInUp' : ''"
          >
            We Will Gain <span>Mutual Trust</span>
          </div>
          <div class="item-text" :class="isFixed3 ? 'animated fadeInUp' : ''">
            We are an honest and reliable dropshipping agent. We focus on
            solving clients' problems by the quick response. Regardless of order
            volume, we treat our clients equally, because we believe in
            everyone's potential!
          </div>
          <div class="item-button row-end">
            <div class="">Transparent &Flexible</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 计算价格区域 -->
    <div class="home-price width-100 direction">
      <div class="price-title">Is Dropshipping Profitable？</div>
      <div class="price-title-min">Gross Monthly Income Calculator</div>
      <div class="row price-slider">
        <div class="price-slider-item">
          <div class="width-100 price-slider-item-center">
            <div class="width-100 space-between price-slider-item-text">
              <div>Product Cost Price</div>
              <div>
                ${{ priceNum == 0 ? "5" : parseInt(priceNum * 100 * 5) }}
              </div>
            </div>
            <div class="width-100">
              <slider
                v-model.number="priceNum"
                width="414"
                height="31"
                :line-width="396"
                :line-height="25"
                :ball-size="15"
              ></slider>
            </div>
          </div>
          <div class="width-100 price-slider-item-center">
            <div class="width-100 space-between price-slider-item-text">
              <div>Profit Margin</div>
              <div>{{ marNum == 0 ? "10" : parseInt(marNum * 100 * 4) }}%</div>
            </div>
            <div class="width-100">
              <slider
                v-model.number="marNum"
                width="414"
                height="31"
                :line-width="396"
                :line-height="25"
                :ball-size="15"
              ></slider>
            </div>
          </div>
          <div class="width-100 price-slider-item-center">
            <div class="width-100 space-between price-slider-item-text">
              <div>Units Sold/Month</div>
              <div>{{ monthNum == 0 ? "10" : parseInt(monthNum * 1000) }}</div>
            </div>
            <div class="width-100">
              <slider
                v-model.number="monthNum"
                width="414"
                height="31"
                :line-width="396"
                :line-height="25"
                :ball-size="15"
              ></slider>
            </div>
          </div>
        </div>
        <div class="price-slider-text direction" v-if="homeObj">
          <div class="price-slider-title">{{ homeObj.revenue.title }}</div>
          <div class="price-num">${{ price }}</div>
          <!-- <p class="mb-2">Dropshipping is still worth in 2022.</p>
          <p class="mb-5">
            The sky’s the limit. Goshippro will free you to make more value to your store.
          </p> -->
          <p  class="mb-5" v-html="homeObj.revenue.content">
          </p>
          <div
            class="price-see-button flex-center pointer"
            @click="calculateClick"
          >
            See the result
          </div>
          <p>Click the button after set all the indicators.</p>
        </div>
      </div>
    </div>
    <!-- 合作商区域 -->
    <div class="cooperative-main width-100 direction pb-4 pt-4 mt-5">
      <div class="cooperative-center direction">
        <div class="cooperative-center-title">Seamless Integration</div>
        <div class="cooperative-center-title-one">
          We are able to integrate with the industry 's biggest shopping
          companies.
        </div>
        <img :src="homeObj.integrate" alt="" />
      </div>
    </div>
    <!-- 评论区 -->
    <div class="width-100 say-main">
      <div class="say-main-ttle width-100">
        <p>What</p>
        <p>Custeromer Say</p>
      </div>
      <div class="width-100 say-center space-between">
        <div
          class="say-item column pointer"
          :class="sayIndex == index ? 'say-item-active' : ''"
          @click="sayIndex = index"
          v-for="(item, index) in homeObj.says"
          :key="index"
        >
          <div class="say-item-text">
            <img
              src="../../assets/home-12.png"
              v-if="sayIndex == index"
              alt=""
            />
            <img src="../../assets/home-11.png" v-else alt="" />
            <div class="text-ellipsis">
              {{ item.content }}
            </div>
          </div>
          <div class="row say-avatar">
            <img :src="item.icon" alt="" />
            <div>{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="say-nav pointer" v-if="homeObj">
        <a :href="homeObj.link.url" target="_blank">{{ homeObj.link.title }} ></a>
      </div>
    </div>
    <video-vue
      :src="homeObj.video"
      v-if="videoShow"
      @hide="videoShow = false"
      :videoType="homeObj.video_type"
    ></video-vue>
    <!-- 主体区域结束 -->
    <feet-view></feet-view>
  </div>
</template>

<script>
import heardView from "../../components/heard.vue";
import feetView from "../../components/feet.vue";
import slider from "../../components/slider.vue";
import api from "../../api/index";
import videoVue from "../../components/video.vue";
export default {
  components: {
    heardView,
    feetView,
    slider,
    videoVue,
  },

  data() {
    return {
      isFixed: false,
      isFixed1: false,
      isFixed2: false,
      isFixed3: false,
      priceNum: 0,
      marNum: 0,
      monthNum: 0,
      price: 55,
      sayIndex: 0,
      videoShow: false,
      homeObj: {},
      homeBg: "",
    };
  },

  created() {
    console.log(this.$route);
    this.getHome();

  },

  mounted() {

    window.addEventListener("scroll", this.handleScroll); // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
  },
  destroyed() {
    window.removeEventListener("scroll");
  },
  methods: {
    // 获取数据
    getHome() {
      api
        .getHome()
        .then((res) => {

          const reg = new RegExp("\n", "g");
          res.data.revenue.content = decodeURIComponent(res.data.revenue.content).replace(
            reg,
            "<br>"
          );
            this.homeObj = res.data;
          if (res.data.use_img) {
            this.homeBg = res.data.use_img;
          } else {
            this.homeBg = require("@/assets/home-5.png");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    calculateClick() {
      let priceNum;
      let marNum;
      let monthNum;
      this.priceNum == 0
        ? (priceNum = 5)
        : (priceNum = parseInt(this.priceNum * 100 * 5));
      this.marNum == 0 ? (marNum = 10) : (marNum = parseInt(this.marNum * 400));
      this.monthNum == 0
        ? (monthNum = 10)
        : (monthNum = parseInt(this.monthNum * 1000));
      this.price = priceNum * monthNum + (priceNum * monthNum * marNum) / 100;
    },
    navTo(url) {
      this.$router.push(url);
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; // 滚动条偏移量
      let offsetTop = document.querySelector("#boxFixed").offsetTop - 800; // 要滚动到顶部吸附的元素的偏移量
      let offsetTop1 = document.querySelector("#boxFixed1").offsetTop - 800; // 要滚动到顶部吸附的元素的偏移量
      let offsetTop2 = document.querySelector("#boxFixed2").offsetTop - 800; // 要滚动到顶部吸附的元素的偏移量
      let offsetTop3 = document.querySelector("#boxFixed3").offsetTop - 800; // 要滚动到顶部吸附的元素的偏移量
      this.isFixed = scrollTop > offsetTop ? true : false; // 如果滚动到顶部了，this.isFixed就为true
      this.isFixed1 = scrollTop > offsetTop1 ? true : false; // 如果滚动到顶部了，this.isFixed就为true
      this.isFixed2 = scrollTop > offsetTop2 ? true : false; // 如果滚动到顶部了，this.isFixed就为true
      this.isFixed3 = scrollTop > offsetTop3 ? true : false; // 如果滚动到顶部了，this.isFixed就为true
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  width: 100%;
  background: #fff;
  // 主体区域一
  .home-main {
    height: 1026px;
    background: #fff;
    background-image: url("../../assets/home.png");
    background-repeat: no-repeat;
    background-size: cover;

    overflow: hidden;
	color: #fff;

	.home-heard-main{
		padding: 193px 6vw 15px 6vw;
		align-items: flex-start;
		margin-bottom: 97px;
	}
	.home-left{
		height: 338px;
		padding-top: 11px;
		justify-content: space-between;
		font-family: Noto Sans-Bold, Noto Sans;
    overflow: hidden;
		.data_view_left{
			div:first-child{
				font-weight: bold;
				font-size: 32px;
				margin-bottom: 24px;
			}
			div:last-child{
				font-size: 16px;
				width: 376px;
			}
		}
		.view_vheck_text{
			.view_left{
				margin-bottom: 24px;
				.view_left_title{
					margin-left: 22px;
					font-weight: bold;
					font-size: 32px;
				}
			}
			.view_text{
				width: 376px;
				font-size: 16px;
			}
		}
	}
	.home-main_video {
	  width: 35.3%;
	  background: #ffffff;
	  padding: 120px 25px 125px 50px;
    margin: 0 58px;
    img {
      max-width: 80% !important;
      height: auto !important;
      object-fit: inherit;
    }
	  .main_video_img {
	    position: absolute;
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%, -50%);
	    width: 52px;
	    height: 52px;
	  }
	}
	.main_data_view{
		height: 372px;
    flex-wrap: nowrap;
		img{
			width: 326px;
			height: 291px;
			margin-right: 28px;
		}
		.data_view_right{
			justify-content: space-between;
			.view_right_hours{
				margin-bottom: 14px;
				i {
				  font-size: 20px;
				}
				.hours_text {
				  font-size: 32px;
				  margin-right: 10px;
				}
				.min-title {
				  font-size: 24px;
				  color: #333333;
				}
			}
			.view_hours_title{
				color: #666666;
				font-size: 16px;
				width: 188px;
			}
		}
	}
	.main-button {
	  margin-top: 48px;
	  margin-bottom: 229px;
	  div {
	    width: 200px;
	    height: 50px;
	    line-height: 50px;
	    font-size: 18px;
	    color: #fff;
	    text-align: center;
	    border-radius: 50px;
	  }
	}
	.strengths-ttle{
		font-size: 32px;
		font-weight: bold;
		color: #333333;
	}
	.strengths-ttle_min{
		color: #333333;
		font-size: 16px;
	}
  }
  // 教程区域二
  .home-goshippro {
    height: 794px;
    background-position-x: center;
    background-size: contain;
    background-repeat: no-repeat;
    .home-goshippro-posi {
      background: rgba(0, 0, 0, 0.6);
      padding-top: 48px;
      padding-bottom: 71px;
      font-weight: bold;
      color: #ffffff;
      font-size: 32px;
      .home-main_video {
        width: 784px;
        height: 450px;
        background: #ffffff;
        padding: 159px 32px 167px 67px;
        margin-top: 66px;
        margin-bottom: 37px;
        .main_video_img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 52px;
          height: 52px;
        }
      }
      .main-button {
        div {
          width: 200px;
          height: 50px;
          line-height: 50px;
          font-size: 18px;
          color: #fff;
          text-align: center;
          border-radius: 50px;
        }
      }
    }
  }
  // 优势区域
  .home-strengths {
    background: #fff;
    padding: 90px 0 116px 0;
    color: #333333;
    font-size: 16px;
    .strengths-ttle {
      font-size: 32px;
      font-weight: bold;
      height: 58px;
    }
    .strengths-ttle_min {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 103px;
    }
    .strengths-item {
      margin-bottom: 133px;
      height: 312px;
      img {
        width: 423px;
        height: 291px;
      }
      .strengths-item-text {
        margin-right: 136px;
        padding-top: 37px;
        .item-text-title {
          font-size: 44px;
          font-weight: bold;
          color: #333333;
          width: 514px;
          line-height: 60px;
        }
        .item-text {
          margin-top: 16px;
          width: 450px;
          line-height: 24px;
        }
        .item-button {
          width: 244px;
          height: 42px;
          background: #ff652b;
          border-radius: 21px;
          color: #ffffff;
          font-size: 14px;
          text-align: center;
          line-height: 42px;
          margin-top: 24px;
        }
      }
    }
    .strengths-item-one {
      margin-bottom: 134px;
      height: 354px;
      img {
        width: 418px;
        height: 336px;
      }
      .strengths-item-text {
        padding-top: 49px;
        margin-left: 194px;
        .item-text-title {
          width: 531px;
          height: 124px;
          font-size: 44px;
          font-weight: bold;
          color: #333333;
          margin-bottom: 16px;
          span {
            color: #73cbff;
          }
        }
        .item-text {
          width: 460px;
          height: 99px;
          margin-bottom: 24px;
        }
        .item-button {
          div {
            width: 130px;
            height: 42px;
            background: #73cbff;
            border-radius: 21px;
            text-align: center;
            line-height: 42px;
            color: #ffffff;
            font-size: 14px;
          }
        }
      }
    }
    .strengths-item-copy {
      margin-bottom: 74px;
      height: 291px;
      img {
        width: 387px;
      }
      .strengths-item-text {
        padding-top: 26px;
        margin-right: 225px;
        .item-text-title {
          width: 530px;
          height: 124px;
          margin-bottom: 16px;
          span {
            color: #2cb757;
          }
        }
        .item-text {
          width: 468px;
          height: 50px;
        }
        .item-button {
          background: #2cb757;
        }
      }
    }
    .strengths-item-one-copy {
      margin-bottom: 0;
      height: 355px;
      img {
        width: 463px;
        height: 347px;
      }
      .strengths-item-text {
        margin-left: 149px;
        padding-top: 57px;
        .item-text-title {
          margin-bottom: 16px;
          span {
            color: #4d5498;
          }
        }
        .item-text {
          width: 438px;
          height: 92px;
          text-align: justify;
        }
        .item-button {
          div {
            width: 244px;
            text-align: center;
            line-height: 42px;
            border-radius: 21px;
            height: 42px;
            background: #38397c;
          }
        }
      }
    }
  }
  // 计算价格区域
  .home-price {
    height: 764px;
    background: #333333;
    padding-top: 56px;
    .price-title {
      width: 530px;
      height: 58px;
      font-size: 32px;
      font-weight: bold;
      color: #ffffff;
    }
    .price-title-min {
      height: 29px;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 103px;
    }
    .price-slider {
      .price-slider-item {
        width: 414px;
        .price-slider-item-center {
          margin-bottom: 46px;
        }
        .price-slider-item-text {
          padding-right: 12px;
          font-weight: bold;
          color: #ffffff;
          font-size: 16px;
          margin-bottom: 8px;
        }
        .slider-view {
          height: 31px;
          background: #ff9d78;
          border-radius: 16px;
        }
      }
      .price-slider-text {
        width: 408px;
        height: 382px;
        background: #ffffff;
        border-radius: 10px 10px 10px 10px;
        margin-left: 321px;
        padding-top: 7px;
        .price-slider-title {
          height: 43px;
          font-weight: bold;
          color: #333333;
          font-size: 24px;
          margin-bottom: 10px;
        }
        .price-num {
          font-weight: bold;
          font-size: 36px;
          height: 50px;
          color: #333333;
          margin-bottom: 15px;
        }
        p {
          text-align: left;
          width: 82%;
          margin: 0 auto;
          color: #666666;
          font-size: 16px;
        }
        .price-see-button {
          width: 162px;
          height: 42px;
          background: #ff652b;
          border-radius: 21px;
          margin-bottom: 30px;
          color: #ffffff;
          font-size: 14px;
        }
      }
    }
  }
  // 合作商区域
  .cooperative-main {
    img {
      width: 1235px;
      height: 421px;
    }
    .cooperative-center-title {
      font-weight: bold;
      color: #333333;
      margin-bottom: 16px;
      font-size: 32px;
    }
    .cooperative-center-title-one {
      font-weight: bold;
      color: #333333;
      font-size: 16px;
      margin-bottom: 4px;
    }
  }
  // 评论区
  .say-main {
    padding: 45px 0 89px 0;
    background-image: url("../../assets/home-13.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .say-main-ttle {
      font-weight: bold;
      color: #333333;
      font-size: 32px;
      padding-left: 440px;
      height: 111px;
      margin-bottom: 161px;
    }
    .say-center {
      width: 1226px;
      margin: 0 auto;
      margin-bottom: 145px;
      .say-item {
        width: 346px;
        height: 408px;
        background: #ffffff;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
        padding: 30px;
        justify-content: space-between;
        .say-item-text {
          color: #333333;
          font-size: 16px;
          text-align: left;
          img {
            width: 65px;
            height: 53px;
            margin-bottom: 30px;
          }
          .text-ellipsis {
            -webkit-line-clamp: 10;
          }
        }
        .say-avatar {
          color: #333333;
          font-size: 18px;
          img {
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }
        }
      }
      .say-item-active {
        background: #ffaf8f;
        box-shadow: 3px 3px 20px 1px rgba(255, 101, 43, 0.3);
        .say-item-text {
          color: #fff;
        }
        .say-avatar {
          color: #fff;
        }
      }
    }
    .say-nav {
      text-align: center;
      font-weight: bold;
      color: #333333;
      font-size: 32px;
      a {
        text-decoration: none;
        color: #ff652b;
      }
    }
  }
}
</style>
